import {
  Response, InvalidEmail, EmailSentSuccessfully, UserNotFound,
} from '@/constants';
import { checkRequireField, isValidEmail } from '@/util/validation';

const checkValidation = (email:string) => ({
  emailMessage: checkRequireField(email, 'email'),
  emailValidation: isValidEmail(email) ? '' : InvalidEmail().message,
});

const resetEmailApiResponse = (message:any) => {
  let returnObj;
  if (message.status === EmailSentSuccessfully) {
    returnObj = Response.SENT;
  } else if (message.status === UserNotFound) {
    returnObj = Response.USERNOTFOUND;
  } else {
    returnObj = Response.EMAILERROR;
  }
  return returnObj;
};

export { checkValidation, resetEmailApiResponse };
