import { Response } from '@/constants';
import { isValidEmail, isValidPhoneNumber, isRequired } from '@/util/validation';

const checkFormValidations = (contactUs: {
  email: string;
  phone: string;
  name: string;
  message: string;
  division: string;
}): {
  email: boolean;
  phone: boolean;
  name: boolean;
  message: boolean;
  division: boolean;
} => ({
  email: isValidEmail(contactUs.email),
  phone: isValidPhoneNumber(contactUs.phone),
  name: isRequired(contactUs.name),
  message: isRequired(contactUs.message),
  division: isRequired(contactUs.division) && contactUs.division !== 'Choose',
});

const contactUsFormApiResponse = (response: any) => {
  let returnObj;
  if (response.status === 200) {
    returnObj = Response.SUCCESS;
  } else {
    returnObj = Response.ERROR;
  }
  return returnObj;
};

export { checkFormValidations, contactUsFormApiResponse };
