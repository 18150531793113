<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.98 183.32">
    <defs>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="328.67 66.07 328.67 182.32 504.92 182.32 504.92 66.82 414.92 9.07 328.67 64.57 328.67 66.07"
        />
        <polygon
          class="cls-2"
          points="328.67 66.07 328.67 182.32 504.92 182.32 504.92 66.82 414.92 9.07 328.67 64.57 328.67 66.07"
        />
        <rect class="cls-3" x="307.67" y="0.82" width="217.5" height="85.5" />
        <path
          class="cls-4"
          d="M415.67.82l3,1.5-3,4.5-3-4.5Zm3,1.5-6,9,106.5,74.25,6-8.25-106.5-75m-111,73.5,6,9,105-73.5-6-9-105,73.5"
        />
        <path
          class="cls-5"
          d="M415.67.82l3,1.5-3,4.5-3-4.5Zm3,1.5-6,9,106.5,74.25,6-8.25-106.5-75m-111,73.5,6,9,105-73.5-6-9-105,73.5"
        />
        <line class="cls-6" x1="307.67" y1="75.82" x2="415.67" y2="0.82" />
        <line class="cls-6" x1="415.67" y1="0.82" x2="525.17" y2="77.32" />
        <line class="cls-6" x1="525.17" y1="77.32" x2="519.17" y2="85.57" />
        <line class="cls-6" x1="519.17" y1="85.57" x2="505.92" y2="75.82" />
        <line class="cls-6" x1="307.67" y1="75.82" x2="313.67" y2="84.82" />
        <line class="cls-6" x1="313.67" y1="84.82" x2="327.92" y2="75.82" />
        <line class="cls-7" x1="327.92" y1="75.82" x2="342.17" y2="66.82" />
        <line class="cls-6" x1="327.92" y1="75.82" x2="327.92" y2="182.15" />
        <line class="cls-6" x1="327.92" y1="182.15" x2="504.92" y2="182.32" />
        <line class="cls-6" x1="504.92" y1="182.32" x2="504.92" y2="76.16" />
        <path
          class="cls-4"
          d="M13.11,90.43,29.9,135,46.66,90.43H59.73v58.21H49.65V129.45l1-25.67L33.46,148.64H26.23L9.08,103.82l1,25.63v19.19H0V90.43Z"
        />
        <path
          class="cls-4"
          d="M85.88,134.72l8.79-29.34H105l-17.2,49.81q-3.94,10.92-13.43,10.92a17.1,17.1,0,0,1-4.68-.73v-7.55l1.84.12a9.39,9.39,0,0,0,5.54-1.34A8.86,8.86,0,0,0,80,152.12l1.4-3.72-15.19-43H76.72Z"
        />
        <path
          class="cls-4"
          d="M157.76,148.64H147.68V122.81h-26v25.83H111.54V90.43h10.12v24.22h26V90.43h10.08Z"
        />
        <path
          class="cls-4"
          d="M167,126.61a25.52,25.52,0,0,1,2.52-11.46,18.4,18.4,0,0,1,7.08-7.83A19.9,19.9,0,0,1,187,104.58q8.76,0,14.22,5.64t5.89,15l0,2.28a25.72,25.72,0,0,1-2.46,11.43,18.12,18.12,0,0,1-7,7.8,20.09,20.09,0,0,1-10.58,2.76q-9.15,0-14.65-6.1T167,127.09Zm9.72.84q0,6.67,2.76,10.45a9.62,9.62,0,0,0,15.33-.06q2.75-3.84,2.74-11.23,0-6.56-2.82-10.4a9,9,0,0,0-7.66-3.84,8.9,8.9,0,0,0-7.55,3.78Q176.67,119.93,176.67,127.45Z"
        />
        <path
          class="cls-4"
          d="M224.52,105.38l.28,4.52q4.56-5.33,12.48-5.32,8.67,0,11.87,6.64a15.34,15.34,0,0,1,13.28-6.64q7.15,0,10.65,4t3.58,11.67v28.43h-9.72V120.49c0-2.74-.6-4.76-1.8-6s-3.18-1.92-5.95-1.92a8.12,8.12,0,0,0-5.42,1.78,9.4,9.4,0,0,0-2.94,4.66l0,29.67h-9.71V120.17q-.21-7.64-7.8-7.64-5.84,0-8.28,4.76v31.35h-9.71V105.38Z"
        />
        <path
          class="cls-4"
          d="M305.68,149.44q-9.23,0-15-5.82T285,128.13v-1.2a26.06,26.06,0,0,1,2.5-11.58,19.09,19.09,0,0,1,7-7.93,18.56,18.56,0,0,1,10.07-2.84q8.83,0,13.66,5.64t4.81,16v3.92H294.77a12.93,12.93,0,0,0,3.58,8.47,10.73,10.73,0,0,0,7.89,3.12,13.17,13.17,0,0,0,10.88-5.4l5.23,5a17.47,17.47,0,0,1-6.93,6A21.81,21.81,0,0,1,305.68,149.44Zm-1.16-37.07a8.2,8.2,0,0,0-6.45,2.8,14.51,14.51,0,0,0-3.14,7.8h18.51v-.72q-.31-4.87-2.6-7.38A8.14,8.14,0,0,0,304.52,112.37Z"
        />
        <path
          class="cls-8"
          d="M383.24,149.52H344.31v-6.64l19.29-21a48.15,48.15,0,0,0,5.94-7.77,12.6,12.6,0,0,0,1.77-6.27,9.49,9.49,0,0,0-2.33-6.68,8.07,8.07,0,0,0-6.26-2.54,9.31,9.31,0,0,0-7.28,2.86,11.28,11.28,0,0,0-2.58,7.84H343.2a19,19,0,0,1,2.4-9.53,16.61,16.61,0,0,1,6.88-6.58,21.93,21.93,0,0,1,10.32-2.35q8.43,0,13.3,4.24T381,106.77a19.89,19.89,0,0,1-2.44,9.09,51.37,51.37,0,0,1-8,10.79l-14.16,15.16h26.88Z"
        />
        <path
          class="cls-8"
          d="M420.29,128.88h7.2v7.76h-7.2v12.88h-9.67V136.64h-25l-.28-5.89,25-39.13h10Zm-24.82,0h15.15V104.7l-.71,1.28Z"
        />
        <path class="cls-8" d="M451.26,128.8H430.38V121h20.88Z" />
        <path
          class="cls-8"
          d="M493.15,97,470,149.52H459.86L483,99.37H453V91.62h40.13Z"
        />
      </g>
      </g>
  </svg>
</template>

<style lang="postcss">
.cls-1, .cls-4 {
  fill: #005ca5;
}
.cls-1, .cls-5, .cls-7 {
  stroke: #005ca5;
}
.cls-1, .cls-6, .cls-7 {
  stroke-width: 2px;
}
.cls-2, .cls-3, .cls-5, .cls-6, .cls-7 {
  fill: none;
}
. cls-2 {
  stroke: #007ec4;
}
.cls-2, .cls-5 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.75px;
}
.cls-6 {
  stroke: #231f20;
}
.cls-8 {
  fill: #fff;
}
</style>
